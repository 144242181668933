import Vue from 'vue'
import VueRouter from 'vue-router'
import List from '../views/List.vue'
// import store from '../store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'List',
    component: List,
    meta: {
      title: '战报列表',
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    meta: {
      title: '战报提交',
    }
  },

  {
    path: '/detail',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue'),
    meta: {
      title: '客户详情',
    }
  },
  {
    path: '/image',
    name: 'Image',
    component: () => import(/* webpackChunkName: "about" */ '../views/Image.vue'),
    meta: {
      title: '战报图片',
    }
  },
  {
    path: '/chooseid',
    name: 'Chooseid',
    component: () => import(/* webpackChunkName: "about" */ '../views/Chooseid.vue'),
    meta: {
      title: '选择客户经理',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: '登陆页面',
    }
  },
]



const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // let timer = setTimeout(() => {
    // if (store.state.token || to.path === '/login') {
    //   clearTimeout(timer)
    //   /* 路由发生变化修改页面title */
      if (to.meta.title) {
        document.title = to.meta.title
        next()
      }
    // } else {
    //   next({
    //     path:'login'
    //   })
    // }
  // }, 100)

})



export default router
