import axios from 'axios'
import { Message } from 'element-ui'

const baseURL = 'https://cms.mthmjj.com/index.php?s='

const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})


service.interceptors.response.use(
    /**
     * 如果你想要获取http信息，例如头信息或状态信息
     * 请返回 响应 => 响应
    */
  
    /**
     * 通过自定义代码确定请求状态
     * 这里只是一个例子
     * 您也可以通过HTTP状态码来判断状态
     */
    response => {
      const res = response.data
  
      // 如果自定义代码不是1，则判断为错误.
      if (res.code !== 0) {
  
        Message({
          message: res.message || '出现错误，请稍后再试',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(res.msg || '出现错误，请稍后再试'))
      } else {
        return res
      }
    },
    error => {
      console.log('err' + error) // for debug
      Message({
        message: error.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  )
  
  export default service
  