<template>
  <div class="content" style="margin-top: 20px">
    <el-button type="primary" class="add" icon="el-icon-plus" @click="onAdd()"
      >新增</el-button
    >
    <el-button type="primary" icon="el-icon-upload2" @click="onExport()"
      >导出excel</el-button
    >
    <div class="search-div">
      <el-row :gutter="10">
        <el-col :xs="10" :sm="6" :md="8" :lg="6" :xl="5">
          <el-input
            placeholder="请输入客户名称/手机号"
            v-model="search_obj.name"
            clearable
          ></el-input>
        </el-col>
        <el-col :xs="30" :sm="16" :md="14" :lg="14" :xl="8">
          <el-date-picker
            v-model="search_obj.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="2">
          <el-button type="primary" @click="onQuery()">查询</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="hidden-md-and-down">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="belong_time" label="签单时间"></el-table-column>
        <el-table-column prop="clerk_name" label="客户经理"></el-table-column>
        <el-table-column prop="client_name" label="客户姓名"></el-table-column>
        <el-table-column prop="client_mobile" label="客户电话">
        </el-table-column>

        <el-table-column
          class-name="hidden-md-and-down"
          prop=""
          label="客户地址"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.country +
              scope.row.province +
              scope.row.city +
              scope.row.region +
              scope.row.client_address
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          class-name="hidden-md-and-down"
          prop="clinch_money"
          label="订单金额"
        >
        </el-table-column>
        <el-table-column
          prop="clinch_products"
          label="成交产品"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>{{ scope.row.clinch_products }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="clinch_products"
          label="客户状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>{{ scope.row.status == 10 ? "正常" : "已撤销" }}</div>
          </template>
        </el-table-column>

        <el-table-column
          class-name="hidden-md-and-down"
          prop="create_time"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div>
              <span
                style="margin-right: 10px; color: #409eff"
                @click="onDetail(scope.row.turn_id)"
                >详情</span
              >
              <el-button
                type="danger"
                size="mini"
                @click="onDelete(scope.row.turn_id)"
                v-if="scope.row.status == 10"
                >撤销</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="hidden-lg-and-up">
      <div v-for="(item, i) in tableData" :key="i">
        <el-descriptions
          class="margin-top"
          title=""
          :column="1"
          size="mini"
          border
        >
          <template slot="extra">
            <el-button
              type="primary"
              size="small"
              @click="onDetail(item.turn_id)"
              >详情</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="onDelete(item.turn_id)"
              v-if="item.status == 10"
              >撤销</el-button
            >
          </template>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              签单时间
            </template>
            {{ item.belong_time }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user-solid"></i>
              客户经理
            </template>
            {{ item.clerk_name }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              客户名称
            </template>
            {{ item.client_name }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              客户手机号
            </template>
            {{ item.client_mobile }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              客户居住地
            </template>
            {{ item.city }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-attract"></i>
              成交金额
            </template>
            {{ item.clinch_money }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              客户联系地址
            </template>
            <div class="descrip">
              {{
                item.province + item.city + item.region + item.client_address
              }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-notebook-2"></i>
              成交产品
            </template>
            <div class="descrip">
              {{ item.clinch_products }}
            </div>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              客户状态
            </template>

            <div class="descrip">
              {{ item.status == 10 ? "正常" : "已撤销" }}
            </div>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-time"></i>
              创建时间
            </template>
            <div class="descrip">
              {{ item.create_time }}
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <el-empty description="暂无数据" v-if="tableData.length == 0"></el-empty>
    </div>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="per_page"
      layout="prev, pager, next, jumper"
      :total="total"
      hide-on-single-page
    >
    </el-pagination>
  </div>
</template>

<script>
import { getList, delData ,getRole} from "../api/list";
import "element-ui/lib/theme-chalk/display.css";

export default {
  data() {
    return {
      // 列表数据
      tableData: [],
      // token
      token: "",
      // 当前页数
      page: 1,
      // 总条数
      total: 0,
      // 一页总条数
      per_page: 15,
      //
      search_obj: {
        name: "",
        time: "",
      },
    };
  },
  created() {
    this.token = this.$store.state.token;

    this.onGetList();
  },
  methods: {
    // 新增
    onAdd() {

      getRole({ec_clerk_token:this.token}).then(res=>{
        if (res.data.this_clerk.is_super == 1) {
          let list = res.data.clerk_list.filter(item=>{
            if (item.accountuser) {
              return item
            }
          })


          localStorage.setItem('clerk_list',JSON.stringify(list))
          this.$router.push({ name: "Chooseid" });
        }else{
          this.$router.push({ name: "Index" });
        }
      })
    },

    // 导出excel
    onExport() {
      window.location.href =
        "https://cms.mthmjj.com/index.php?s=/platform/eccrm.clerk/turnover_excel&ec_clerk_token=" +
        this.token;
    },

    // 获取数据列表
    onGetList() {
      let start_time = "",
        end_time = "";

      if (this.search_obj.time.length > 0) {
        start_time = new Date(this.search_obj.time[0])
          .toLocaleDateString()
          .replace(/\//g, "-");
        end_time = new Date(this.search_obj.time[1])
          .toLocaleDateString()
          .replace(/\//g, "-");
      }

      let data = {
        ec_clerk_token: this.token,
        page: this.page,
        search: this.search_obj.name,
        start_time: start_time,
        end_time: end_time,
      };
      getList(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },

    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.onGetList();
    },
    // 删除
    onDelete(id) {
      console.log(id);
      

      this.$confirm("是否撤销该订单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delData({ ec_clerk_token: this.token, turn_id: id }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "撤销成功!",
              });
              this.onGetList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤销",
          });
        });
    },
    onQuery() {
      this.onGetList();
    },
    onDetail(id) {
      this.$router.push({ name: "Detail", query: { turn_id: id } });
    },
  },
};
</script>

<style>
.search-div {
  margin: 20px 0;
}

.descrip {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.margin-top {
  margin-top: 20px;
}
</style>
