import request from "../utils/request"

export function getList(data) {
    return request({
      url: '/platform/eccrm.clerk/turnover_list',
      method: 'post',
      data,
    })
}


export function delData(data) {
  return request({
    url: '/platform/eccrm.clerk/turnover_invalid',
    method: 'post',
    data,
  })
}

export function getRole(data) {
  return request({
    url: '/platform/eccrm.clerk/get_clerk_list',
    method: 'post',
    data,
  })
}
