<template>
  <div id="app">
    <el-row :gutter="20" type="flex" justify="center">
      <el-col :sm="15">
        <!-- <h2>{{ this.$route.meta.title }}</h2> -->
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    if(!this.$store.state.token && location.href.split("=")[0].includes("ec_clerk_token")){
      const token = location.href.split("=")[1].split("#")[0];
      this.$store.commit('saveToken',token)
      localStorage.setItem('token',token)
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
